import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    register(email) {
        return window.axios.post(this.baseUrl + 'api/register/create-user', {login: email})
    },
    changePassword(iduser, form) {
        return window.axios.post(this.baseUrl + 'api/register/' + iduser + '/update-password', form)
    },
    resetPassword(email) {
        return window.axios.post(this.baseUrl + 'api/register/reset-password', {email})
    },
    resetPasswordToken(form) {
        return window.axios.post(this.baseUrl + 'api/register/token-reset-password', form)
    },
    getUser() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(this.baseUrl + 'api/user', config)
    },
    verifyUser(iduser){
        return window.axios.post(this.baseUrl + 'api/register/'+ iduser +'/verify')
    },
    impersonate(id) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.post(this.baseUrl + 'api-m/auth/' + id + '/impersonate', {}, config);
    },
}