<template>
    <app-page no-header>
        <section class="mb-0 mb-sm-5">
            <center-container col-class="col-xs-no-gutter">
                <div v-if="state === 'account'">
                    <div class="d-flex align-items-center justify-content-center py-5 bg-secondary text-white">
                        <div class="mw-4">
                            <b-img fluid
                                   class="rounded-circle"
                                   :src="user.avatar"/>
                        </div>
                        <p class="family-bold display-3 ml-3"
                           v-html="`${user.prenom}<br>${user.nom}`"></p>
                    </div>
                    <div class="bg-white pt-5 px-3">
                        <b-form-group v-for="(input, key) in inputs"
                                      :label="input.label"
                                      :label-for="`input-${key}`"
                                      :key="`input-${key}`">
                            <div class="activable-input">
                                <b-form-input
                                    :class="{'activable-input__input': true, 'font-weight-bold': true, 'disabled-input': input.type == 'tel'}"
                                    @click="input.beforeEdit()"
                                    :id="`input-${key}`"
                                    v-model="input.value"
                                    :type="input.type"
                                    :ref="key"
                                    :autocomplete="input.autocomplete"
                                    :disabled="!input.editing"
                                ></b-form-input>
                                <button class="activable-input__btn" v-if="input.editable"
                                        @click="input.btnAction()">
                                    <b-icon-eye-slash v-show="input.type != 'password'"
                                                      class="activable-input__icon"></b-icon-eye-slash>
                                    <b-icon-eye v-show="input.type == 'password'"
                                                class="activable-input__icon"></b-icon-eye>
                                </button>
                            </div>
                        </b-form-group>
                        <div class="d-flex flex-column align-items-center">
                            <b-button :disabled="!canUpdate"
                                      size="lg"
                                      variant="primary"
                                      @click="updateAction">Modifier
                            </b-button>
                            <b-button variant="link"
                                      class="mt-3"
                                      @click="disconnectAction">Se déconnecter
                            </b-button>
                        </div>
                    </div>
                </div>
                <transition name="content-fade">
                    <b-row v-if="state === 'loading'"
                           class="d-flex justify-content-center flex-wrap">
                        <b-col class="text-white text-center"
                               xl="6" lg="7" md="9" sm="11">
                            <p class="pt-4 pb-5">Déconnexion en cours...</p>
                            <b-spinner></b-spinner>
                        </b-col>
                    </b-row>
                </transition>
            </center-container>
        </section>
    </app-page>
</template>

<script>
import {mapState} from 'vuex'
import {BIconEye, BIconEyeSlash, BIconPencilSquare, BIconXSquare} from 'bootstrap-vue'
import CenterContainer from '@/components/layout/CenterContainer'
import AppPage from '@/components/layout/AppPage'
import compteService from '../../../services/compteService'
import AlertHandler from '@/alertHandler'
import authenticationService from "../../../services/authenticationService";

export default {
    components: {AppPage, CenterContainer, BIconPencilSquare, BIconXSquare, BIconEye, BIconEyeSlash},
    computed: {
        ...mapState({
            user: state => state.user
        }),
        canUpdate() {
            return Object.keys(this.inputs).some(key => {
                return this.inputs[key].value !== this.user[key] && this.inputs[key].isValid(this.inputs[key].value)
            })
        }
    },
    watch: {
        contactData(val) {
            this.initForm();
        }
    },
    mounted() {
        this.updateUserInfo()
    },
    data() {
        return {
            state: 'account',
            inputs: [],
            contactData: null,
            confirmPassword: '',
        }
    },
    methods: {
        editAction(key) {
            if (!this.inputs[key].editing) {
                this.inputs[key].editing = true
                if (this.inputs[key].beforeEdit) {
                    this.inputs[key].beforeEdit()
                }
                this.$nextTick(() => {
                    this.$refs[key][0].$el.focus()
                })
                return
            }
            this.inputs[key].value = this.user[key]
            this.inputs[key].editing = false
        },
        disconnectAction() {
            this.state = 'loading'
            this.$auth.logout()
        },
        initForm() {
            this.inputs = [];
            this.contactData?.forEach(contact => {
                this.addInput({
                    label: 'Téléphone',
                    value: contact.contact_value,
                    type: 'tel',
                    autocomplete: 'tel',
                    editable: false,
                })
            })
            this.addInput({
                placeholder: 'placeholder',
                label: 'Mot de passe',
                value: 'placeholder',
                type: 'password',
                autocomplete: 'new-password',
                editing: true,
                init: false,
                beforeEdit: function () {
                    if (this.init) return;
                    this.init = true;
                    this.value = ''
                },
                btnAction: function () {
                    this.beforeEdit();
                    if (this.type === 'password') this.type = 'text';
                    else this.type = 'password';
                },
                action: (e) => this.confirmPassword = e,
            })
            this.addInput({
                placeholder: 'placeholder',
                label: 'Confirmation',
                value: 'placeholder',
                type: 'password',
                autocomplete: 'new-password-confirmation',
                editing: true,
                init: false,
                beforeEdit: function () {
                    if (this.init) return;
                    this.init = true;
                    this.value = '';
                },
                btnAction: function () {
                    this.beforeEdit();
                    if (this.type === 'password') this.type = 'text';
                    else this.type = 'password';
                },
                action: (e) => {
                    if (e != this.confirmPassword) {
                        AlertHandler.show({title: 'Attention', message: 'Les mots de passes sont différents'});
                        return;
                    }

                    if (e == 'placeholder' || e.length == 0) return;
                    authenticationService
                        .changePassword(this.user.idutilisateur, {
                            password: e,
                            password_confirmation: e
                        })
                        .then(res => {
                            if (res.data.etat == "OK") {
                                AlertHandler.show({code: 'password-change'});
                            } else {
                                AlertHandler.show({title: 'Attention', message: res.data.data?.[0]})
                            }
                        })
                        .catch(error => {
                            AlertHandler.show({code: 4010, error})
                        })
                },
            })
        },
        updateUserInfo() {
            compteService.getInformations()
                .then((response) => {
                    if (response.data.etat === 'OK') {
                        this.contactData = response.data.data?.contacts;
                    } else {
                        AlertHandler.show({code: 3008});
                    }
                })
                .catch((error) => {
                    AlertHandler.show({code: 3007, error})
                });
        },
        addInput({
                     placeholder = '',
                     label = '',
                     value = '',
                     type = 'text',
                     autocomplete = '',
                     editing = false,
                     beforeEdit = () => {
                     },
                     action = () => {
                     },
                     btnAction = () => {
                     },
                     isValid = () => true,
                     editable = true
                 }) {
            this.inputs.push({
                placeholder,
                label,
                value,
                type,
                autocomplete,
                editing,
                beforeEdit,
                action,
                isValid,
                editable,
                btnAction
            });
        },
        updateAction() {
            Object.keys(this.inputs).forEach(key => {
                if (this.inputs[key].value !== this.user[key] && this.inputs[key].isValid(this.inputs[key].value)) {
                    this.inputs[key].action(this.inputs[key].value)
                }
            })
        }
    }
}
</script>

<style scoped>
.disabled-input {
    background-color: lightgray;
}
</style>